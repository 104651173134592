<template>
	<div class="order_wrap">
		<MStepper />
		<v-container class="">
			<div class="txt_box px-6">
				<h1 class="tit_page">수납 (복합결제-신용카드)</h1>
				<p class="txt_body1 mb-8">
					고객님이 납부하실 신용카드 정보를 등록해 주세요.
					<br />
					<span class="primary--text mt-2">
						총 <b> {{ recpInfos.amount | comma }} 원</b>의 수납금액 중 <br />
						신용카드 수납금액은
						<b>{{ recpInfos.cardAmount | comma }} 원</b> 입니다</span
					>
				</p>
			</div>
			<CardPayForm ref="card" :hidePaymentDate="true" />
			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="goRecp()">
					신용카드 등록
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn>
				<CustomRecpDialog ref="recpPop" />
			</div>
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
import { complexCardTransfer } from '@/apis/order.recp.api'

export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '', //04:판매인 07:고객수납
				cardAmount: 0,
				vaccAmount: 0
			},
			vaccountParams: {
				TC_VTACCT: {},
				TO_DISPLAY: []
			},
			dpParams: {
				account: '',
				date: '',
				amount: ''
			}
		}
	},
	mounted() {
		this.recpInfos = this.$store.getters['recp/recpInfos']

		const { status } = this.$route.query
		if (status) {
			this.status = status
		}
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async goRecp() {
			const formData = this.$refs.card.onSubmit()
			if (!formData) return
			this.$log('card formData:', formData)

			this.$log('recpInfos:', JSON.stringify(this.recpInfos))

			let payRecp = {
				AF_OWNER_NM: formData.custName,
				AF_BANK_CD_NM: formData.bankNm,
				AF_CARD_NO: formData.accountNo
			}

			this.$store.dispatch('recp/setPayRecp', payRecp)

			const options = {
				title: '다음 카드를 통해 결제를 요청합니다.',
				contents: {
					수납방법: `${formData.bankNm} 
					${this.$options.filters.star(formData.accountNo, 6)}`,
					수납금액: `${this.$options.filters.comma(
						this.recpInfos.cardAmount
					)}원`
				},
				okText: '결제',
				cancelText: '수납방법 재선택'
			}

			const popRes = await this.$refs.recpPop.open(options)

			if (popRes) {
				let prm = {
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: '02', //this.recpInfos.mthd,
					userId: this.recpInfos.userId,
					cardn: formData.accountNo,
					avDat: `20${formData.avDatYear}${formData.avDatMonth}`,
					vrCardAmt: this.recpInfos.cardAmount,
					kunnr: this.recpInfos.kunnr,
					mobOrderNo: this.$store.state['verify'].mobOrderNo,
					name: formData.custName,
					depositor: formData.custName,
					ssn: formData.ssn,
					restry: formData.custType,
					bankCd: formData.bankCd
				}

				const res = await complexCardTransfer(prm)
				const rtn = res.resultObject.data.E_RETURN

				if (rtn.TYPE === 'S') {
					// 수납 (복합결제 – 개인 가상계좌) 화면으로 이동
					// TODO store 저장
					this.$store.dispatch(
						'recp/setRecpCardResult',
						res.resultObject.data.TC_CARD[0]
					)
					this.$store.dispatch('recp/setRecpParams', { ...prm })
					this.$router.push({
						name: 'recp-complex-bank',
						query: { status: this.status }
					})
				} else {
					const options = {
						title: '다음과 같은 사유로 수납이 거부됐습니다.',
						guide: '다른 카드로 수납하시기 바랍니다.',
						contents: {
							거부사유: rtn.MESSAGE
						},
						okText: '수납방법 재선택',
						cancelText: ''
					}

					const popRes = await this.$refs.recpPop.open(options)

					if (popRes) {
						// 수납방법 재선택
						this.$router.push({
							name: 'recp-method',
							query: { status: this.status }
						})
					}
				}
			}
		}
	}
}
</script>
